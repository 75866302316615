import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "Posting - XING-Firmenseite",
  "description": "So veröffentlichen Sie automatisiert Stellen auf Ihrem XING-Firmenprofil",
  "author": "Jochen Ritscher",
  "categories": ["getting-started"],
  "date": "2021-08-13T00:00:00.000Z",
  "featured": false
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Öffnen Sie mit einem berechtigten Moderatorenzugang ihr Firmenprofil auf XING.
Klicken Sie auf "Neuigkeiten" und anschließend auf "Neuigkeiten importieren".`}</p>
    <img src="/images/gettingstarted/xing.png" alt="PowerApps" style={{
      "width": "100%"
    }} />
    <p>{`Geben Sie im Feld "Aus Ihren Blogs & Websites per RSS" den Pfad Ihres Karriereportals gefolgt von /jobs.rss ein.
Beispiel: `}<a parentName="p" {...{
        "href": "https://jobs.%5Bihrunternehmen%5D.com/jobs.rss"
      }}>{`https://jobs.[ihrunternehmen].com/jobs.rss`}</a></p>
    <p>{`Bestätigen Sie die Änderung mit Speichern.`}</p>
    <p>{`XING ruft nun zukünftig alle aktuellen Stellenanzeigen innerhalb 30 Minuten automatisch ab und stellt diese als Newsbeitrag dar.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      